import Cookies from 'js-cookie'

import type {ParamCookiesNames} from './cookies'

import {paramCookiesMap} from './cookies'

enum ParamName {
    REFERRAL = 'referral',
    REFERRAL_CODE = 'referralCode',
    UTM_SOURCE = 'utm_source',
    UTM_MEDIUM = 'utm_medium',
    UTM_CAMPAIGN = 'utm_campaign',
    UTM_TERM = 'utm_term',
    UTM_CONTENT = 'utm_content',
    FINANCE_ADS_ID = 's_id',
    META_CLICK_ID = '_fbc',
    META_BROWSER_ID = '_fbp',
    TIKTOK_PIXEL_ID = '_ttcid',
    GOOGLE_CLICK_IDENTIFIER = 'gclid',
    GREENHOUSE_TRACKING_SOURCE = 'gh_src',
    USER_UNIQUE_IDENTIFIER = 'uuid',
}

type ParamCookie = {
    paramName: ParamName
    cookieName: ParamCookiesNames
    cookieNameLatest?: ParamCookiesNames
}

export const paramCookies: ParamCookie[] = [
    {
        paramName: ParamName.REFERRAL,
        cookieName: paramCookiesMap.referral,
    },
    {
        paramName: ParamName.REFERRAL_CODE,
        cookieName: paramCookiesMap.referralCode,
    },
    {
        paramName: ParamName.UTM_SOURCE,
        cookieName: paramCookiesMap.utmSource,
        cookieNameLatest: paramCookiesMap.latestUtmSource,
    },
    {
        paramName: ParamName.UTM_MEDIUM,
        cookieName: paramCookiesMap.utmMedium,
        cookieNameLatest: paramCookiesMap.latestUtmMedium,
    },
    {
        paramName: ParamName.UTM_CAMPAIGN,
        cookieName: paramCookiesMap.utmCampaign,
        cookieNameLatest: paramCookiesMap.latestUtmCampaign,
    },
    {
        paramName: ParamName.UTM_TERM,
        cookieName: paramCookiesMap.utmTerm,
        cookieNameLatest: paramCookiesMap.latestUtmTerm,
    },
    {
        paramName: ParamName.UTM_CONTENT,
        cookieName: paramCookiesMap.utmContent,
        cookieNameLatest: paramCookiesMap.latestUtmContent,
    },
    {
        paramName: ParamName.FINANCE_ADS_ID,
        cookieName: paramCookiesMap.financeAdsCookieName,
    },
    {
        paramName: ParamName.META_CLICK_ID,
        cookieName: paramCookiesMap.metaClickId,
    },
    {
        paramName: ParamName.META_BROWSER_ID,
        cookieName: paramCookiesMap.metaBrowserId,
    },
    {
        paramName: ParamName.TIKTOK_PIXEL_ID,
        cookieName: paramCookiesMap.tikTokPixelId,
    },
    {
        paramName: ParamName.GOOGLE_CLICK_IDENTIFIER,
        cookieName: paramCookiesMap.googleClickIdentifier,
    },
    {
        paramName: ParamName.GREENHOUSE_TRACKING_SOURCE,
        cookieName: paramCookiesMap.greenHouseSource,
        cookieNameLatest: paramCookiesMap.latestGreenHouseSource,
    },
    {
        paramName: ParamName.USER_UNIQUE_IDENTIFIER,
        cookieName: paramCookiesMap.userUniqueIdentifier,
    },
]

export const getParamCookies = () => {
    return paramCookies.reduce((all: Record<string, string>[], {cookieName, cookieNameLatest}) => {
        const cookieValue = Cookies.get(cookieName)

        const result = [
            ...all,
            {
                [cookieName]: cookieValue || '',
            },
        ]

        if (cookieNameLatest) {
            const cookieValueLatest = Cookies.get(cookieNameLatest)

            result.push({
                [cookieNameLatest]: cookieValueLatest || '',
            })
        }

        return result
    }, [])
}

export const setParamCookies = (searchParams: URLSearchParams) => {
    paramCookies.forEach(({paramName, cookieName, cookieNameLatest}) => {
        const paramValue = searchParams.get(paramName)
        const existingCookieValue = Cookies.get(cookieName)

        // If no param, do nothing
        if (!paramValue) {
            return
        }

        // If cookieNameLatest is specified (e.g. pleo_latest_utm_source),
        // set the param value under this cookie name. This cookie will
        // always be overridden by subsequent page visits with the same
        // param and so will always have the latest value
        if (cookieNameLatest) {
            Cookies.set(cookieNameLatest, paramValue, {
                expires: 365,
                ...(window.location.hostname !== 'localhost' && {domain: '.pleo.io'}),
            })
        }

        // If there's no existing cookie, store the param in a cookie. This
        // cookie will never get overridden and will always be the first
        // value from the first visit with this param
        if (!existingCookieValue) {
            Cookies.set(cookieName, paramValue, {
                expires: 365,
                ...(window.location.hostname !== 'localhost' && {domain: '.pleo.io'}),
            })
        }
    })
}
