import {identify} from '@/utils/analytics'
import createTrackingForExperiments from '@/utils/track-page-split-experiments'
import {sessionTrackingProperties} from '@/utils/track-session'

import type {GatsbyBrowser} from 'gatsby'

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
    // Object.fromEntries() polyfill
    if (!Object.fromEntries) {
        Object.defineProperty(Object, 'fromEntries', {
            value(entries: any[]) {
                return [...entries].reduce((obj, [key, val]) => {
                    obj[key] = val
                    return obj
                }, {})
            },
        })
    }

    identify({
        ...sessionTrackingProperties(),
        ...createTrackingForExperiments(),
    })
}
