import styled from 'styled-components'

import type {SpacingValues} from '@pleo-io/telescope-tokens/dist/spacing.types'

type Align = 'left' | 'right' | 'center'

interface Props {
    /**
     * The space between each item. Default 0.
     */
    $space?: 0 | SpacingValues

    $align?: Align
    $stretch?: boolean
}

const alignValues = {
    left: 'start',
    center: 'center',
    right: 'end',
} as const

export const Stack = styled.div<Props>`
    display: grid;
    width: 100%;
    box-sizing: border-box;
    grid-gap: ${({$space}) => $space || 0}px;
    align-content: start;
    justify-items: ${({$stretch, $align = 'left'}) => ($stretch ? 'stretch' : alignValues[$align])};
`
