import {ConsentManagerBuilder} from '@segment/consent-manager'
import React, {useEffect} from 'react'

import {useHubspotConsent} from '@/consent/context'
import {
    ConsentState,
    updateConsentsState,
    setDefaultConsentsState,
} from '@/utils/google-ads-consents'

import type {ConsentParams} from '@/utils/google-ads-consents'
import type {CategoryPreferences} from '@segment/consent-manager/types/types'

type ConsentManagerBuilderRenderProps = {
    saveConsent: (newPreferences?: boolean | CategoryPreferences, shouldReload?: boolean) => void
    setPreferences: (newPreferences: CategoryPreferences) => void
    consentPreferences: Hubspot.ConsentPreferences
}

const SegmentConsentComponent = ({
    saveConsent,
    setPreferences,
    consentPreferences,
}: ConsentManagerBuilderRenderProps) => {
    /**
     * Update consent preferences for Segment and Google Ads
     */
    useEffect(() => {
        const segmentPreferences = mapHubspotConsentToSegmentPreferences(consentPreferences)
        const googleAdsPreferences = mapHubspotConsentToGoogleAdsPreferences(consentPreferences)

        // Determine if user has consented to marketing analytics
        // Blanket use for granting segment consent
        const consentHasBeenGiven = consentPreferences.categories.advertisement

        // Update Segment preferences
        setPreferences(segmentPreferences)

        // Update Segment consent state
        const shouldReload = !consentHasBeenGiven
        saveConsent(consentHasBeenGiven, shouldReload)

        // Update Google Ads preferences
        updateConsentsState(googleAdsPreferences, {cookiePrefix: 'managed'})

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consentPreferences]) // setPreferences has side effects

    return <></>
}

// Map boolean consent values to 'granted' | 'denied'
const mapToConsentStatus = (hasGrantedConsent: boolean): ConsentState => {
    return hasGrantedConsent ? ConsentState.Granted : ConsentState.Denied
}

/**
 * Maps the consent preferences from Hubspot to our Segment consent preferences
 */
const mapHubspotConsentToSegmentPreferences = ({
    categories: {advertisement, analytics, functionality, necessary},
}: Hubspot.ConsentPreferences): CategoryPreferences => {
    return {
        necessary: necessary,
        performance: functionality,
        functional: functionality,
        advertising: advertisement,
        marketing: advertisement,
        marketingAndAnalytics: advertisement && analytics,
    }
}

/**
 * Maps the consent preferences from Hubspot to our Google Ads consent preferences
 *
 *  Read more here:
 *  https://developers.hubspot.com/beta-docs/reference/api/analytics-and-events/cookie-banner/cookie-banner-api
 */
const mapHubspotConsentToGoogleAdsPreferences = ({
    categories: {advertisement, analytics},
}: Hubspot.ConsentPreferences): ConsentParams => {
    return {
        // Consent for personalized advertising
        ad_personalization: mapToConsentStatus(advertisement),

        // Consent for sending user data related to advertising
        ad_user_data: mapToConsentStatus(advertisement),

        // Enables storage related to advertising
        ad_storage: mapToConsentStatus(advertisement),

        // Enables storage related to analytics (e.g., visit duration)
        analytics_storage: mapToConsentStatus(analytics),
    }
}

const HubspotManagedConsent = () => {
    const hubspotConsent = useHubspotConsent()
    const consentPreferences = hubspotConsent.consentPreferences

    React.useEffect(() => {
        setDefaultConsentsState()
    }, [])

    return (
        <ConsentManagerBuilder writeKey={process.env.GATSBY_SEGMENT_WRITE_KEY!}>
            {(consentProps) => (
                <SegmentConsentComponent
                    consentPreferences={consentPreferences}
                    {...consentProps}
                />
            )}
        </ConsentManagerBuilder>
    )
}

const ConsentManager = ({hideFooterBanner}: {hideFooterBanner: boolean}) => {
    const hubspotConsent = useHubspotConsent()

    if (hideFooterBanner || hubspotConsent.isLoading) {
        return null
    }

    return <HubspotManagedConsent />
}

export default ConsentManager
