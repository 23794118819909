module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-styled-components@5.25.0_babel-plugin-styled-components@2.1.4_@babel+core@7.26._kib6x6b35fjuzrazs2rjhr4sh4/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":false,"disableVendorPrefixes":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-segment-js@5.0.0_gatsby@4.25.7_@swc+core@1.7.39_@swc+helpers@0.5.15__@types+web_7z7hhavs6tvsphb5dq5sblu3um/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"host":"https://cdn.insights.pleo.io","prodKey":"1SribV2gq3K37QKlY2hMY75wQzlbvTF4","devKey":"1SribV2gq3K37QKlY2hMY75wQzlbvTF4","trackPage":false,"manualLoad":true},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-hubspot@2.0.0_gatsby@4.25.7_@swc+core@1.7.39_@swc+helpers@0.5.15__@types+webpac_dlalkunaxicmidvqhmtf7drg3m/node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"2694217","respectDNT":true,"productionOnly":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-datadog-rum@1.0.1/node_modules/gatsby-plugin-datadog-rum/gatsby-browser.js'),
      options: {"plugins":[],"applicationId":"450ad43c-54b6-4fe8-a2f4-e9a0395b38a4","clientToken":"pub828d87fb3413da442efee5cbed22399a","site":"datadoghq.eu","service":"commercial-website","sampleRate":100,"enabled":true},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-gtag@5.14.0_gatsby@4.25.7_@swc+core@1.7.39_@swc+helpers@0.5.15__@types+w_2r3hmpe2mspagmbkneb3fk77j4/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["GTM-TVF6PG8"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@4.25.7_@swc+core@1.7.39_@swc+helpers@0.5.15__@types+webpack@5.28.5_@swc+core@1.7.39_@s_a4i5nkfq6izhagmn6dgsi77mm4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
