import {FaroErrorBoundary} from '@grafana/faro-react'
import {PrismicPreviewProvider} from 'gatsby-plugin-prismic-previews'
import React from 'react'

import {LaunchDarklyProvider} from '@/feature-flags/launch-darkly'
import {GoogleReCaptchaProvider} from '@/providers/google-recaptcha'
import {ThemeProvider} from '@/providers/theme'

import type {ReactNode} from 'react'

// Adds a shared React Context for Prismic preview sessions.
type WrapRootElementProps = {
    children: ReactNode
}
export const WrapRootElement = ({children}: WrapRootElementProps) => (
    <FaroErrorBoundary>
        <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_V3_SITE_KEY!}>
            <PrismicPreviewProvider>
                <ThemeProvider>
                    <LaunchDarklyProvider
                        clientSideID={process.env.GATSBY_LAUNCH_DARKLY_CLIENT_ID!}
                    >
                        {children}
                    </LaunchDarklyProvider>
                </ThemeProvider>
            </PrismicPreviewProvider>
        </GoogleReCaptchaProvider>
    </FaroErrorBoundary>
)
