import React from 'react'
import {ThemeProvider as RootThemeProvider} from 'styled-components'

import {theme} from '@/theme'

import type {ReactNode} from 'react'

type Props = {
    children: ReactNode
}

export const ThemeProvider = ({children}: Props) => (
    <RootThemeProvider theme={theme}>{children}</RootThemeProvider>
)
