import React from 'react'

import {Text} from '@/components/text'
import {useGlobalStrings} from '@/providers/global-strings'

import type {TextProps} from '@/components/text'
import type {GlobalStrings} from '@/types/global-strings'

interface Props extends TextProps {
    globalStringKey: keyof GlobalStrings
}

export const GlobalText = ({globalStringKey, ...props}: Props) => {
    const globalStrings = useGlobalStrings()

    const text = globalStrings[globalStringKey] as string

    return text ? <Text {...props}>{text}</Text> : null
}
