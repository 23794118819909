import * as EmailValidator from 'email-validator'
import Cookies from 'js-cookie'
import React from 'react'

import {
    emailSignupActioned,
    EmailSignupActionedAction,
    performanceMarketingSignupActioned,
    PerformanceMarketingSignupActionedAction,
    Referral,
} from '@/analytics/segment'
import {cookiesMap} from '@/config/cookies'
import {useHubspotProperties} from '@/hooks/use-hubspot-properties'
import {useIsCompanyEmail} from '@/hooks/use-is-company-email'
import useNavigate from '@/hooks/use-navigate'
import {useSignupRedirect} from '@/hooks/use-signup-redirect'
import {useLocalStorage} from '@/hooks/use-storage'
import useSubmit from '@/hooks/use-submit'
import {useSubmitHubspotForm} from '@/hooks/use-submit-hubspot-form'
import {useGoogleReCaptcha} from '@/providers/google-recaptcha'
import {useLocale} from '@/providers/locale'
import {usePageInfo} from '@/providers/page-info'
import {
    LatestLeadSourceCaptureType,
    LifecycleStages,
    HubspotPartnerChannel,
    LeadType,
} from '@/types/marketing-campaigns'
import {getUserTraits, identify} from '@/utils/analytics'
import {isEmptyObject} from '@/utils/is-empty-object'
import {isGenericEmail} from '@/utils/is-generic-email'
import {wait} from '@/utils/wait'

import type {Completions} from '@/components/navattic-demo'
import type {HubspotContactProps} from '@/types/hubspot-contact-props'
import type {LeadInterestLevel} from '@/types/marketing-campaigns'

export interface Args {
    redirectPath?: string
    form: string
    trackQualifyingLeads?: boolean
    leadInterestLevel?: LeadInterestLevel
    params?: Record<string, string> | Record<string, string[]>
    isPartner?: boolean
}

export enum ErrorType {
    INVALID_EMAIL = 'INVALID_EMAIL',
    NON_WORK_EMAIL = 'NON_WORK_EMAIL',
}

const useSignup = (
    {form, trackQualifyingLeads = true, leadInterestLevel, isPartner, ...props}: Args,
    formId: string,
) => {
    const currentLocale = useLocale()
    const [email, setEmail] = React.useState('')
    const {pageContext} = usePageInfo()
    const isCompanyEmail = useIsCompanyEmail(email)
    const isValidEmail = EmailValidator.validate(email)
    const navigate = useNavigate()
    const [navatticDemoCompletion] = useLocalStorage<Completions>('navatticDemoCompletion', {})

    const navatticParams = !isEmptyObject(navatticDemoCompletion)
        ? {
              navattic_flows_completed: navatticDemoCompletion.completions,
              navattic_flow_progress: navatticDemoCompletion.flowProgress,
          }
        : null

    const accountingPartnerRedirectProps = {
        redirectPath: `${process.env.GATSBY_PLEO_APP_URL}/partner/signup`,
        params: {email, country: currentLocale.country},
    }

    const accountingPartnerHubSpotProperties: Partial<HubspotContactProps> = {
        lead_source_latest_capture_type: LatestLeadSourceCaptureType.SelfOnboardingPartner,
        type_of_lead: LeadType.AccountingPartner,
        partner_channel: HubspotPartnerChannel.AccountingPartner,
        country: currentLocale.country,
    }

    const redirectPath = useSignupRedirect(
        isPartner
            ? accountingPartnerRedirectProps
            : {
                  redirectPath: props.redirectPath,
                  params: {...props.params, email, ...navatticParams},
              },
    )
    const {recaptchaHubspotSubmit} = useSubmitHubspotForm(formId, false)
    const hubspotProperties = useHubspotProperties()
    const {loadReCaptcha} = useGoogleReCaptcha()
    const external_id = React.useId()

    const isReferralLandingPage = pageContext.uid === 'referrals' ? Referral.Yes : Referral.No

    const handleSignup = async (e: React.FormEvent<HTMLFormElement> | undefined) => {
        e?.preventDefault()

        if (!isValidEmail || !isCompanyEmail) {
            emailSignupActioned({
                action: EmailSignupActionedAction.Blocked,
                referral: isReferralLandingPage,
                form,
                email,
            })
        }

        if (!isValidEmail) {
            throw new Error(ErrorType.INVALID_EMAIL)
        }

        if (!isCompanyEmail) {
            throw new Error(ErrorType.NON_WORK_EMAIL)
        }

        if (!currentLocale.isPreLaunch) {
            emailSignupActioned({
                action: EmailSignupActionedAction.Submitted,
                referral: isReferralLandingPage,
                form,
                email,
            })
        }

        identify({
            external_id,
            externalId: external_id,
            form,
            email,
        })

        if (trackQualifyingLeads && !email?.match(/.*gmail.*/i)) {
            const userTraits = getUserTraits()
            performanceMarketingSignupActioned(
                {
                    action: PerformanceMarketingSignupActionedAction.Started,
                    value: 1,
                    fbc: Cookies.get(cookiesMap.metaClickId) || '',
                    fbp: Cookies.get(cookiesMap.metaBrowserId) || '',
                    ttclid: Cookies.get(cookiesMap.tikTokPixelId) || '',
                },
                userTraits,
            )
        }

        await recaptchaHubspotSubmit({
            ...hubspotProperties,
            lead_interest_level: leadInterestLevel,
            email,
            genericEmail: isGenericEmail(email),
            lead_source_latest_capture_type: LatestLeadSourceCaptureType.SelfOnboarding,
            lifecyclestage: LifecycleStages.Subscriber,
            ...(isPartner && accountingPartnerHubSpotProperties),
        })

        /**
         * A short timeout to give the track calls more time to complete
         * before redirecting. Works like Segment's `trackForm` method, but
         * allows us to programmatically invoke the track events.
         *
         * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track-form
         */
        await wait(400)

        if (redirectPath.startsWith('http')) {
            window.location.assign(redirectPath)
        } else {
            navigate(redirectPath)
        }
    }

    const {handleSubmit, loading, error, clearError} = useSubmit(handleSignup)

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        loadReCaptcha()
        clearError()
        setEmail(e.target.value)
    }

    return {
        handleSubmit,
        handleEmailChange,
        email,
        loading,
        error,
        clearError,
        setEmail,
    }
}

export default useSignup
