import {useStaticQuery, graphql} from 'gatsby'
import {LazyMotion} from 'motion/react'
import React from 'react'

import ConsentManager from '@/components/consent-manager'
import NotificationBanner from '@/components/notification-banner'
import SkipToContent from '@/components/skip-to-content'
import {HubspotConsentProvider} from '@/consent'
import {useLocalizedContent} from '@/hooks/use-localized-content'
import {useSWPostMessage} from '@/hooks/use-sw-post-message'
import {GlobalStringsProvider} from '@/providers/global-strings'
import {LocaleProvider} from '@/providers/locale'
import {PageInfoProvider} from '@/providers/page-info'
import {SignupModalProvider} from '@/providers/signup-modal'
import GlobalStyles from '@/styles/global-styles'

import type {GlobalStringsWebsiteQuery} from '@/graphql-types'
import type {PageProps} from '@/types/page-props'
import type {ReactNode} from 'react'

// Don't show the notification banner on these pages
const notificationBannerOmittedPaths = [
    '/expense-policy-builder',
    '/expense-report-roi-calculator',
    '/expense-report-generator',
    '/pricing',
    '/de/bigform-partner-datev',
    '/careers/benefits',
    '/referrals',
    '/explore-product',
    '/getting-started',
]

const domAnimation = () => import('@/utils/dom-animation').then((res) => res.default)

type Props = Omit<PageProps, 'children'> & {children: ReactNode}

export const WrapPageElement = ({children, ...props}: Props) => {
    const pathnameIncludes = (path: string) => new RegExp(path).test(props.location.pathname)

    // We share the page locale code to the SW thread in order to optimize the Previews api calls.
    // Note that in production this hook is replaced with a dummy function since Previews are disabled.
    useSWPostMessage({type: 'SET_PREVIEWS_LANGUAGE', message: props.pageContext.locale})

    const queryParams = new URLSearchParams(props.location.search)
    const hideFooterBanner = queryParams.get('hide-cookie-banner') === 'true'
    const hideNotificationBanner =
        notificationBannerOmittedPaths.some(pathnameIncludes) ||
        queryParams.get('partner') === 'datev'

    const hasFinancialPartnerDisclaimer = props.pageContext.locale === 'en-gb'
    const notificationBannerContent = props.pageContext.notificationMessage
    const {allPrismicGlobalStrings} = useStaticQuery<GlobalStringsWebsiteQuery>(query)
    const [content] = useLocalizedContent(allPrismicGlobalStrings.nodes, {
        localeCode: props.pageContext.locale,
    })
    const globalStrings = {
        ...content.data,
        notification_banner_message: content.data.notification_banner_message.richText,
        recaptcha_and_privacy_terms: content.data.recaptcha_and_privacy_terms.richText,
        accept_terms_and_privacy: content.data.accept_terms_and_privacy.richText,
        clearbitCredits: content.data.clearbit_credits.richText,
        marketingEmailsConsentText: content.data.marketing_emails_consent_text.richText,
        yourEmailLabel: content.data.your_email_label,
        playVideo: content.data.play_video,
        localeSelectorGlobalLabel: content.data.locale_selector_global_label,
        signUp: content.data.sign_up,
        skipToContent: content.data.skip_to_content,
        consent_label: content.data.consent_label.richText,
        watchTheVideo: content.data.watch_the_video,
        privacyConsentText: content.data.privacy_consent_text.richText,
        financial_partner_disclaimer: hasFinancialPartnerDisclaimer
            ? content.data.financial_partner_disclaimer
            : '',
    }

    return (
        <>
            <GlobalStyles />

            <PageInfoProvider {...props}>
                <HubspotConsentProvider>
                    <LocaleProvider localeCode={props.pageContext.locale}>
                        <GlobalStringsProvider content={globalStrings}>
                            <LazyMotion features={domAnimation} strict>
                                <SkipToContent />

                                <ConsentManager hideFooterBanner={hideFooterBanner} />

                                {!hideNotificationBanner && (
                                    <NotificationBanner content={notificationBannerContent} />
                                )}

                                <SignupModalProvider>{children}</SignupModalProvider>
                            </LazyMotion>
                        </GlobalStringsProvider>
                    </LocaleProvider>
                </HubspotConsentProvider>
            </PageInfoProvider>
        </>
    )
}

const query = graphql`
    query GlobalStringsWebsite {
        allPrismicGlobalStrings {
            nodes {
                lang
                data {
                    notification_banner_message {
                        richText
                    }
                    close_button_text
                    visit_website
                    read_more
                    learn_more
                    show_more
                    watch_now
                    recaptcha_and_privacy_terms {
                        richText
                    }
                    show_me
                    all
                    copied_to_clipboard
                    something_went_wrong
                    first_name
                    last_name
                    work_email
                    company_name
                    company_size
                    select
                    phone_number
                    submit
                    accept_terms_and_privacy {
                        richText
                    }
                    this_field_is_required
                    must_be_company_email
                    sole_trader
                    employee
                    employees
                    your_full_name
                    your_work_email
                    please_select_number_of_employees
                    please_enter_your_work_email
                    please_enter_a_work_email
                    sticky_cta_button_text
                    play
                    admin
                    industry
                    location
                    days
                    hours
                    minutes
                    back
                    home
                    menu
                    invalid_email_error
                    loading
                    clearbit_credits {
                        richText
                    }
                    play_video
                    preview
                    mins_read
                    min_read
                    latest_articles
                    tags
                    panel
                    related_posts_text
                    sequence_posts_text
                    non_work_email_error_cta_text
                    non_work_email_error
                    country
                    locale_selector_global_label
                    marketing_emails_consent_text {
                        richText
                    }
                    your_email_label
                    sign_up
                    skip_to_content
                    consent_label {
                        richText
                    }
                    country_placeholder
                    discovered_via_label
                    discovered_via_placeholder
                    demo_submit_text
                    other
                    peer_recommendation
                    trust_pilot
                    google_search
                    youtube
                    tv
                    outdoor_advertising
                    social_media
                    press
                    first_name_error
                    last_name_error
                    phone_number_error
                    company_size_error
                    country_error
                    has_consented_error
                    get_started
                    watch_the_video
                    file_upload_too_large
                    file_type
                    upload_text
                    your_message
                    send_message
                    form_submission_error
                    num_customers
                    consents_error
                    feedback
                    interest_message_label
                    contact_sales
                    back_to_pleo_io
                    financial_partner_disclaimer
                    privacy_consent_text {
                        richText
                    }
                    email
                    address
                    city
                    postcode
                    state
                    invoice_number
                    issued_on
                    due_date
                    company_logo
                    currency
                    free_plan_cost_label
                }
            }
        }
    }
`
