/*
 * This component is inspired from react-google-recaptcha-v3
 * https://github.com/t49tran/react-google-recaptcha-v3
 *
 * With the original component there as no way to lazy load google recaptcha
 * so we decided to reimplement it and enhance it the loadReCaptcha function
 * while removing anything we didn't need.
 */
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'

import type {ReactNode} from 'react'

interface IGoogleReCaptchaProviderProps {
    reCaptchaKey: string
    children: ReactNode
}

export interface IGoogleReCaptchaConsumerProps {
    executeRecaptcha?: (action?: string) => Promise<string>
    loadReCaptcha: () => void
}

const GoogleReCaptchaContext = createContext<IGoogleReCaptchaConsumerProps>({
    executeRecaptcha: () => {
        // This default context function is not supposed to be called
        throw Error(
            'GoogleReCaptcha Context has not yet been implemented, if you are using useGoogleReCaptcha hook, make sure the hook is called inside component wrapped by GoogleRecaptchaProvider',
        )
    },
    loadReCaptcha: () => {},
})

const {Consumer: GoogleReCaptchaConsumer} = GoogleReCaptchaContext

const useGoogleReCaptcha = () => useContext(GoogleReCaptchaContext)

export function GoogleReCaptchaProvider({reCaptchaKey, children}: IGoogleReCaptchaProviderProps) {
    const [recaptchaIsNeeded, setRecaptchaIsNeeded] = useState<boolean>(false)
    const [greCaptchaInstance, setGreCaptchaInstance] = useState<null | {
        execute: Function
    }>(null)
    const clientId = useRef<number | string>(reCaptchaKey)

    useEffect(() => {
        if (!recaptchaIsNeeded) {
            return
        }

        if (!reCaptchaKey) {
            console.warn('<GoogleReCaptchaProvider /> recaptcha key not provided')

            return
        }

        const scriptId = 'google-recaptcha-v3'

        const onLoad = () => {
            if (!window || !(window as any).grecaptcha) {
                console.warn(`<GoogleRecaptchaProvider /> 'Recaptcha script is not available'`)

                return
            }

            const grecaptcha = (window as any).grecaptcha

            grecaptcha.ready(() => {
                setGreCaptchaInstance(grecaptcha)
            })
        }

        const onError = () => {
            console.warn('Error loading google recaptcha script')
        }

        injectGoogleReCaptchaScript({
            reCaptchaKey,
            onLoad,
            onError,
        })

        return () => {
            cleanGoogleRecaptcha(scriptId)
        }
    }, [reCaptchaKey, recaptchaIsNeeded])

    const executeRecaptcha = useCallback(
        (action?: string) => {
            if (!greCaptchaInstance || !greCaptchaInstance.execute) {
                throw new Error('<GoogleReCaptchaProvider /> Google Recaptcha has not been loaded')
            }

            return greCaptchaInstance.execute(clientId.current, {action})
        },
        [greCaptchaInstance, clientId],
    )

    const googleReCaptchaContextValue = useMemo(
        () => ({
            executeRecaptcha: greCaptchaInstance ? executeRecaptcha : undefined,
            loadReCaptcha: () => setRecaptchaIsNeeded(true),
        }),
        [executeRecaptcha, greCaptchaInstance],
    )

    return (
        <GoogleReCaptchaContext.Provider value={googleReCaptchaContextValue}>
            {children}
        </GoogleReCaptchaContext.Provider>
    )
}

interface IInjectGoogleReCaptchaScriptParams {
    reCaptchaKey: string
    onLoad: () => void
    onError: () => void
}

/**
 * Function to clean the recaptcha script injected by the recaptcha.js
 */
const cleanGstaticRecaptchaScript = () => {
    const script = document.querySelector(
        'script[src^="https://www.gstatic.com/recaptcha/releases"]',
    )

    if (script) {
        script.remove()
    }
}

/**
 * Function to check if script has already been injected
 *
 * @param scriptId
 * @returns
 */
const isScriptInjected = (scriptId: string) => !!document.querySelector(`#${scriptId}`)

/**
 * Function to clean node of badge element
 */
const cleanBadge = () => {
    const nodeBadge = document.querySelector('.grecaptcha-badge')
    if (nodeBadge && nodeBadge.parentNode) {
        document.body.removeChild(nodeBadge.parentNode)
    }
}

/**
 * Function to clean google recaptcha script
 *
 * @param scriptId
 */
const cleanGoogleRecaptcha = (scriptId: string) => {
    // remove badge
    cleanBadge()

    // remove old config from window
    /* eslint-disable @typescript-eslint/no-explicit-any */
    ;(window as any).___grecaptcha_cfg = undefined

    // remove script
    const script = document.querySelector(`#${scriptId}`)
    if (script) {
        script.remove()
    }

    cleanGstaticRecaptchaScript()
}

/**
 * Function to inject the google recaptcha script
 *
 * @param param0
 * @returns
 */
const injectGoogleReCaptchaScript = ({
    reCaptchaKey,
    onLoad,
}: IInjectGoogleReCaptchaScriptParams) => {
    const scriptId = 'google-recaptcha-v3'

    // Script has already been injected, just call onLoad and does othing else
    if (isScriptInjected(scriptId)) {
        onLoad()

        return
    }

    // Generate the js script
    const googleRecaptchaSrc = 'https://www.google.com/recaptcha/api.js'
    const googleRecaptchaScript = document.createElement('script')
    googleRecaptchaScript.id = scriptId
    googleRecaptchaScript.src = `${googleRecaptchaSrc}?render=${reCaptchaKey}`
    googleRecaptchaScript.onload = onLoad

    // Append it to the body
    document.body.appendChild(googleRecaptchaScript)
}

export {GoogleReCaptchaConsumer, useGoogleReCaptcha}
