import React from 'react'

import locales from '@/locale/locales'

import type {LocaleCode} from '@/locale/locales'
import type Locale from '@/locale/types'
import type {ReactNode} from 'react'

export const getDefaultLocale = () => {
    const defaultLocale = locales.find((locale) => locale.default)

    if (!defaultLocale) {
        throw new Error('Default locale not found!')
    }

    return defaultLocale
}

const LocaleContext = React.createContext<Locale>(getDefaultLocale())

type Props = {
    children: ReactNode
    localeCode: LocaleCode
}

export const LocaleProvider = ({children, localeCode}: Props) => {
    const locale =
        locales.find((locale) => {
            return locale.code === localeCode
        }) || getDefaultLocale()

    return <LocaleContext.Provider value={locale}>{children}</LocaleContext.Provider>
}

export const useLocale = () => React.useContext(LocaleContext)
